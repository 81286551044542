import { useCallback } from 'react';

import { ModeGame } from 'core/widgets/Auth/types';

export const useCloseGame = (
  mode: ModeGame,
  message: string | null,
  redirectURL: string | null,
) => {
  const closeGame = useCallback(() => {
    if (mode === ModeGame.IFRAME) {
      window.parent.postMessage(message ? message : 'GT:EXIT', '*');

      return;
    }

    if (mode === ModeGame.HOLI && redirectURL) {
      window.location.href = redirectURL;
      window.opener = null;
      window.open('', '_self');
      window.close();
      return;
    }

    if (mode === ModeGame.HOLI && !redirectURL) {
      if (document.referrer) {
        window.location.href = document.referrer;
      } else {
        window.opener = null;
        window.open('', '_self');
        window.close();
      }
    }
  }, [mode, message, redirectURL]);

  return closeGame;
};
