import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IAuthState } from './types';

export const authSelector = (state: IState): IAuthState => state.authReducer;

export const authInfoSelector = createSelector(authSelector, (state: IAuthState) => ({
  operatorId: state.operatorId,
  jackpotOn: state.jackpotOn,
  currency: state.currency,
  uuid: state.uuid,
  mode: state.mode,
  isMobileLandscape: state.isMobileLandscape,
  redirectURL: state.redirectURL,
  messageCloseGame: state.messageCloseGame,
}));
